import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Center.css';
import Treemap from '../Treemap/Treemap';
import { URL, APIKEY } from '../../utils/constants/constants';
import { s, p, f } from '../../utils/constants/formatSpecifiers';
import { Helmet } from 'react-helmet-async';
import image from '../../images/logo.svg';

function Center() {
    const { year: yearParam } = useParams();
    const navigate = useNavigate();
    const [year, setYear] = useState(Number(yearParam) || 2023);
    const [centerData, setCenterData] = useState(null);
    const [value, setValue] = useState();
    const [toggleButton, setToggleButton] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const buttonRef = useRef(null);

    // Update URL when year changes
    useEffect(() => {
        if (yearParam && Number(yearParam) !== year) {
            setYear(Number(yearParam));
        }
    }, [yearParam]);

    useEffect(() => {
        navigate(`/center/${year}`);
    }, [year, navigate]);

    const handleCloseButton = () => {
        setToggleButton(false);
    };

    const handleOpenButton = (e) => {
        e.preventDefault();
        setToggleButton(true);
    };

    const handleNameChange = (value) => {
        setValue(value);
    };

    const handleYearChange = (e) => {
        const selectedYear = Number(e.target.id);
        setYear(selectedYear);
        navigate(`/center/${selectedYear}`);
    };

    // Fetch data whenever the year changes
    useEffect(() => {
        setLoading(true); // Show loading indicator
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'x-api-key': APIKEY },
            body: JSON.stringify({ year }),
        };
        fetch(`${URL}/center`, requestOptions)
            .then((res) => res.json())
            .then((res) => setCenterData(res))
            .finally(() => setLoading(false)) // Hide loading indicator
            .catch((err) => console.log(err));
    }, [year]);

    const navigation = (d) => {
        return d.ancestors().reverse().slice(1).map((d) => (
            <li className='center__map' key={d.data.name}>
                <span className='center__span'>&#8595;</span>
                <p className='center__text'>{d.data.name}<br/>({f(p(d.value))} млн руб.)</p>
                <div className='center__arrow'></div>
            </li>
        ));
    };

    return (
        <>
            <Helmet>
                <title>{`Доходы и расходы федерального бюджета России - ${year}`}</title>
                <meta name="description" content="Cколько тратит федеральный бюджет? Визуализация, Бюджетные данные простым."/>
                <meta property="og:title" content={`Федеральный бюджет России - ${year}`} />
                <meta property="og:description" content="Визуализация доходов и расходов федерального бюджета России" />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={`https://budgetdata.ru/center/${year}`} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={`/center/${year}`} />
            </Helmet>
            <section className="center">
                <div>
                    <div className='center__dropdown' onMouseMove={handleOpenButton} onMouseLeave={handleCloseButton}>
                        <button id={"0"} className="center__dropdown-article-button" ref={buttonRef} onClick={handleOpenButton}>
                            Год&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                        {toggleButton ? (
                            <ul className="center__dropdown-list center__dropdown-list_years" ref={buttonRef} onClick={handleCloseButton}>
                                {[2023, 2022, 2021, 2020].map((y) => (
                                    <li className="center__dropdown-item" key={y}>
                                        <button id={y} className='center__dropdown-button center__dropdown-text' onClick={handleYearChange}>
                                            {y}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                    <div className="center__panel">
                        <p className="center__explanation">{`[Клик x2 - перейти на следующий уровень,\nКлик x1 - посмотреть доп. информацию]`}</p>
                        <ul className='center__test'>
                            <p className='center__value'>Российская Федерация, {year}, млн руб </p>
                            {value}
                        </ul>
                    </div>
                    </div>
                    <div className="center__treemap">
                        {loading ? (
                            <div className='center__loading'>Загрузка...</div>
                        ) : (
                            <Treemap navigation={navigation} onChange={handleNameChange} data={centerData} />
                        )}
                    </div>
            </section>
        </>
    );
}

export default Center;
