import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './Regions.css';
import Treemap from '../Treemap/Treemap';
import RegionsList from '../RegionsList/RegionsList';
import { URL, APIKEY } from '../../utils/constants/constants';
import { s, p, f } from '../../utils/constants/formatSpecifiers';
import data from '../../utils/constants/region_codes.json';
import { regionList } from '../../utils/constants/actions';
import image from '../../images/logo.svg';

function Regions({ state, dispatch }) {
    const { name, year: yearParam } = useParams();
    const [year, setYear] = useState(Number(yearParam) || 2021);
    const [regionsData, setRegionsData] = useState(null);
    const [regionsName, setRegionsName] = useState(null);
    const [loading, setLoading] = useState(false);
    const navRef = useRef(null);
    const navigate = useNavigate();
    const [value, setValue] = useState();
    const buttonRef = useRef(null);
    const [area, setArea] = useState('');

    const handleNameChange = (value) => {
        setValue(value);
    };

    function findUrlNameByCounter() {
        const region = data.find(item => item.id === state.counter);
        return region ? region.name_eng.replace(/\s+/g, '-').toLowerCase() : 'unknown';
    }

    const name_eng = findUrlNameByCounter();

    // Sync URL when `year` changes, but prevent unnecessary updates
    useEffect(() => {
        const currentPath = `/regions/${name_eng}/${year}`;
        if (window.location.pathname !== currentPath) {
            navigate(currentPath, { replace: true });
        }
    }, [year]);

    // Update the `year` from URL param only when it actually differs to prevent infinite updates
    useEffect(() => {
        const paramYear = Number(yearParam);
        if (paramYear && paramYear !== year) {
            setYear(paramYear);
        }
    }, [yearParam]);

    // Fetch data on year or counter change
    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': APIKEY },
            body: JSON.stringify({ counter: state.counter - 1, year }),
        };
        fetch(`${URL}/regions`, requestOptions)
            .then(res => res.json())
            .then(res => {
                setRegionsData(res.data);
                setRegionsName(res.name);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [state.counter, year]);

    // Update `state.counter` if URL name does not match the current counter
    useEffect(() => {
        const region = data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name);
        if (region && region.id !== state.counter) {
            dispatch(regionList(region.id));
        }
    }, [name, state.counter, dispatch]);

    const navigation = (d) => {
        return d.ancestors().reverse().slice(1).map((d) => (
            <li key={d.data.name} className='regions__map'>
                <span className='regions__span'>&#8595;</span>
                <p className='regions__text'>{d.data.name}<br/>({f(p(d.value))} млн руб.)</p>
                <div className='regions__arrow'></div>
            </li>
        ));
    };

    const onChange = (e) => {
        setArea(e.target.value);
    };

    function handleClickOutside(event) {
        if (buttonRef.current && !buttonRef.current.contains(event.target)) {
            setArea('');
        }
    }

    function handleKeydown(e) {
        if (e.key === 'Escape') setArea('');
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeydown);
        };
    }, []);

    const handleRegionChange = (item) => {
        if (typeof item === "number") {
            dispatch(regionList(item));
            setArea("");
        }
        const regionListName = data.find(d => d.id === item).name_eng.replace(/\s+/g, '-').toLowerCase();
        navigate(`/regions/${regionListName}/${year}`);
    };

    const onSearch = (searchTerm) => {
        setArea(searchTerm);
    };

    return (
        <>
            {data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name) &&
            <Helmet>
                <title>{`Бюджет ${regionsName}`}</title>
                <meta name="description" content={`Расходы и доходы ${regionsName}. Простая и понятная визуализация бюджета региона.`} />
                <meta property="og:title" content={`Бюджет ${regionsName}`} />
                <meta property="og:description" content={`Визуализация доходов и расходов ${regionsName}`} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={`https://budgetdata.ru/${name_eng}/${year}`} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={`/regions/${name_eng}/${year}`} />
            </Helmet>
            }
            <section className="regions__content">
                <p className="regions__explanation">{`[Клик x2 - перейти на следующий уровень,\nКлик x1 - посмотреть доп. информацию]`}</p>
                <div className="regions">
                    <div className="regions__list">
                    <RegionsList
                    className="regions__search"
                    setYear={setYear}
                    dispatch={dispatch}
                    state={state}
                    area={area}
                    onChange={onChange}
                    onSearch={onSearch}
                    handleRegionChange={handleRegionChange}
                    ref={buttonRef}
                />
                        <ul className='regions__test'>
                            <p className='regions__value'>{regionsName}, {year}, млн руб.</p>
                            {value}
                        </ul>
                    </div>
                    <div className="regions__treemap">
                        {loading ? (
                            <div className='regions__loading'>Загрузка...</div>
                        ) : (
                            <Treemap year={year} state={state} ref={navRef} navigation={navigation} onChange={handleNameChange} data={regionsData} />
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Regions;
