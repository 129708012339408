import React, { useState, useRef, useEffect, forwardRef } from 'react';
import './RegionsList.css';
import data from '../../utils/constants/region_codes.json';

const RegionsList = forwardRef(({ state, dispatch, area, onSearch, onChange, handleRegionChange, setYear }, ref) => {
    const [toggleButton, setToggleButton] = useState(false); // Toggle for year dropdown
    const [highlightedIndex, setHighlightedIndex] = useState(-1); // Track highlighted search item
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);

    // Filter data for search dropdown
    const filteredData = data.filter(item => {
        const searchTerm = area.toLowerCase();
        const regionName = item.name.toLowerCase();
        return searchTerm && regionName.includes(searchTerm) && regionName !== searchTerm;
    }).slice(0, 5);

    const handleCloseButton = () => {
        setToggleButton(false);
        setHighlightedIndex(-1);
    };

    const handleYearChange = (e) => {
        setYear(e.target.id);
        handleCloseButton();
    };

    const handleOpenButton = () => {
        setToggleButton(true);
    };

    const handleKeyDown = (e) => {
        if (!filteredData.length) return;

        if (e.key === "ArrowDown") {
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % filteredData.length);
        } else if (e.key === "ArrowUp") {
            setHighlightedIndex((prevIndex) => (prevIndex - 1 + filteredData.length) % filteredData.length);
        } else if (e.key === "Enter" && highlightedIndex >= 0) {
            const selectedItem = filteredData[highlightedIndex];
            if (selectedItem) {
                onSearch(selectedItem.name);
                handleRegionChange(selectedItem.id);
                setHighlightedIndex(-1);
                handleCloseButton();
            }
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
    };

    // Close dropdown on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                handleCloseButton();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [highlightedIndex, filteredData]);

    return (
        <>
            <div className="list">
                <div className="list__search-form">
                    <form className="list__search-container" onSubmit={onFormSubmit}>
                        <input
                            className="list__input"
                            type="text"
                            value={area}
                            onChange={onChange}
                            placeholder="Найти регион..."
                        />
                        <div className={`list__dropdown ${area && 'show'}`} ref={dropdownRef}>
                            {filteredData.map((item, index) => (
                                <div
                                    key={item.id}
                                    className={`list_dropdown-row ${index === highlightedIndex ? 'highlighted' : ''}`}
                                    onClick={() => {
                                        onSearch(item.name);
                                        handleRegionChange(item.id);
                                    }}
                                >
                                    {item.name}
                                </div>
                            ))}
                        </div>
                    </form>
                </div>

                <div className="list__dropdown-year" ref={buttonRef} onMouseEnter={handleOpenButton} onMouseLeave={handleCloseButton}>
                    <button
                        id="0"
                        className="list__dropdown-article-button"
                    >
                        Год&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </button>
                    {toggleButton && (
                        <ul
                            className={`list__dropdown-list list__dropdown-list_years ${toggleButton && 'show'}`}
                        >
                            <li className="list__dropdown-item">
                                <button
                                    id="2021"
                                    className="list__dropdown-button list__dropdown-text"
                                    onClick={handleYearChange}
                                >
                                    2021
                                </button>
                            </li>
                            <li className="list__dropdown-item">
                                <button
                                    id="2020"
                                    className="list__dropdown-button list__dropdown-text"
                                    onClick={handleYearChange}
                                >
                                    2020
                                </button>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
});

export default RegionsList;
