import {React, useEffect, useRef, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import './Main.css'
import * as d3 from 'd3'
import mapDesktop from '../../utils/constants/map.geojson'
import mapPhone from '../../utils/constants/mapsmall.geojson'
import {regionMap} from '../../utils/constants/actions'
import { useResizeObserver } from '../../utils/constants/useResizeObserver'
import {s,p,f} from '../../utils/constants/formatSpecifiers'
import {URL, METRICS, ARTICLES, APIKEY} from '../../utils/constants/constants' 
import image from '../../images/logo.svg'
// import { Context } from '../../context/CurrentUserContext'

function Main({dispatch}) {
    const [openButton, setOpenButton] = useState({id: 0, value: false});
    const [year, setYear] = useState(2021)
    const [toggleButton, setToggleButton] = useState(false);
    const [index, setIndex] = useState(0)
    const [item, setItem] = useState(0)
    const [federalValues, setFederalValues] = useState(null)
    const [regionsValues, setRegionsValues] = useState(null)
    const mapRef = useRef();
    const buttonRef = useRef(null);

    const dimensions = useResizeObserver(mapRef);
    
    const handleOpenButton = (e) => {
        e.preventDefault(); 
        setToggleButton(true)
        setOpenButton(e.target.id);
      }

      const handleCloseButton = (e) => {
        setToggleButton(false) 
    }

    const handleYearChange = (e) => {
        setYear(e.target.id);
        console.log(year-2020)
    }
    console.log(year)
      //Убираем dropdown list при клике
      useEffect(() => {
        function handleClickOutside(event) {
          if (buttonRef.current && !buttonRef.current.contains(event.target) && (!mapRef.current || !mapRef.current.contains(event.target)) && (!svgRef.current || !svgRef.current.contains(event.target)))
            {
            setOpenButton(0);  // Change the state if clicked outside the button
            // setToggleButton(!toggleButton)
          }
        }
            document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
      useEffect(() => {
        function onKeyup(e) {
          //define event listener
          if (e.key === 'Escape')
            setOpenButton(0);
            // setToggleButton(!toggleButton)
        }
          //register our listener
        window.addEventListener('keyup', onKeyup);
          //unregister our listener
        return () => window.removeEventListener('keyup', onKeyup);
      }, []);
// console.log(openItems)
    //Покдлючаемcя к федеральным и региональным данным
        useEffect(() => {
            const requestOptions = {
                method: "POST",
                headers: {"Content-Type": "application/json", 'x-api-key': APIKEY},
                body: JSON.stringify(
                    {  year: year-2020,
                       id: ARTICLES[item].id,
                       group_id: ARTICLES[item].group_id, 
                       degree: METRICS[index].degree, 
                       currency: METRICS[index].currency,
                       parent: ARTICLES[item].parent,
                       level: ARTICLES[item].level,
                       percapita: METRICS[index].percapita,
                       perproduct: METRICS[index].perproduct
                    }),
            };
                fetch(`${URL}/`, requestOptions)
                .then((res) => res.json())
                .then((res) => res)
                .then((res) => {setFederalValues(res.center); setRegionsValues(res.regions)})
                .catch((err) => console.log(err))
            },[item, index, year])
        // console.log(federalValues)
        // console.log(regionsValues  && regionsValues)

    const handlePlaceChange = (region) =>{
          dispatch(regionMap(region))
    }

    const navigate = useNavigate();

    const svgRef = useRef();
    useEffect(() =>{
        if (!dimensions) return;
        const width =  dimensions.width
        const height = dimensions.height
        const legendPositionX = () =>{
            if(width>500){
                return 350
            }else{
                return 0
            }
        } 
        const legendPositionY = () =>{
            if(width>500){
                return 350
            }else{
                return 10
            }
        } 

        const map =() => {
            if (width>500) {
                return mapDesktop
            }else{
                return mapPhone
            }
        }

        const scale = () => {
            if (width>350) {
                return 300
            }else if (301<width<350) {
                return 280
            }else{
                return 210
            }
        }   

        const translateX = () =>{
            if(width>400){
                return -150
            }else{
                return -130
            }
        } 
        
        const translateY = () =>{
            if(width > 500){
                return 680
            }else if(width<500){
                return 830
            }
        } 


        d3.select("g").remove()

        const svg = d3.select(svgRef.current)

        const projection = d3.geoMercator()
        .scale(scale()) // This is the zoom
        .translate([translateX(), translateY()]); // передвигаем карту в svg

        const tooltip = d3.select("body").append("div")
        .attr("class", "map__tooltip")
        .style("opacity", 0);

        const tooltipPosition = (event) => {
            event.preventDefault()
            const tooltipWidth = parseInt(getComputedStyle(document.querySelector(".map__tooltip")).width.replace("px", ""))+18
            if (window.screen.width-event.pageX > tooltipWidth) {
            return (event.pageX) + "px"}
            else {
            return (event.pageX-tooltipWidth) + "px"
            }
        }
          //Escape function
          // https://www.caktusgroup.com/blog/2020/07/01/usekeypress-hook-react/

        const path = d3.geoPath()
        .projection(projection)

        const color = d3.scaleQuantile()
        
        // https://htmlcolorcodes.com/colors/shades-of-red/
        // https://colorbrewer2.org/#type=sequential&scheme=OrRd&n=9
        .range(["#AB3131", "#f6cf2d", "#f7d545", "#F9E076", "#D9C782","#FFEF00", "#abd629",  "#799163", "#5d8f2f", "#FCA510", "#507B58", "#006400"])
        //.range(['#800026', '#bd0026', '#feb24c', '#fed976', '#ffeda0','#d9f0a3','#addd8e','#78c679','#41ab5d','#238443','#006837','#004529'])
        //map - это карта по geojson
        const addTooltip = (selection) => {
            if (width>900){
            selection
                .on("mousemove", function(event,d) {
                    tooltip
                    .style("opacity", 1);
                    tooltip.html((d.properties.name) + "<br/>" + d.properties.value.toFixed(1)+" "+ METRICS[index].name)
                    .style("opacity", 1)
                    .style("left", tooltipPosition(event))
                    .style("top", (event.pageY) + "px")
                 })
                 .on("mouseout", function(d, event) {
                     d3.select(this)
                     tooltip
                     .style("opacity", 0);
                 })
                 .on("click", function(event,d){
                     tooltip
                     .style("opacity", 0);

                     handlePlaceChange(d.properties.id)
                     
                     const url = `/regions/${d.properties.name_eng.replace(/\s+/g, '-').toLowerCase()}/${year}`;
                     navigate(url)
                 })
        } else {
            selection
            .on("click", function(event,d) {
                tooltip
                .style("opacity", 1);
                tooltip.html((d.properties.name) + "<br/>" + d.properties.value.toFixed(1)+" "+ METRICS[index].name)
                .style("left", tooltipPosition(event))
                .style("top", (event.pageY) + "px")
             })
             .on("mouseout", function(d, event) {
                 d3.select(this)
                 tooltip
                 .style("opacity", 0);
             })
            .on("dblclick", function(event, d){
                tooltip
                .style("opacity", 0);
                handlePlaceChange(d.properties.id);
                const url = `/regions/${d.properties.name_eng.replace(/\s+/g, '-').toLowerCase()}/${year}`;
                navigate(url)
            })
        }
    }
        d3.json((map())).then(function(json){
            //перебираем от 0 до length. Вот здесь нужно три цикла для dat'ы 
            if (!regionsValues) return; // если прогрузилось, то идем дальше
            for  (var i = 0; i < regionsValues[1].length; i++) {
                for (var j = 0; j < json.features.length; j++) {
                    const jsonState = json.features[j].properties.name;
            // regionsValues[1][i] - это dataState, regionsValues[0][i] - это dataValue
                    if (regionsValues[1][i] === jsonState) {
            //Find the corresponding state inside the GeoJSON - находим в карте свойство name и сравниваем с тем, что есть в spending
                     json.features[j].properties.value = regionsValues[0][i]
                    }
                }
}
            color.domain([
                d3.min(json.features, function(d) { return d.properties.value}), 
                d3.max(json.features, function(d) { return d.properties.value/3})
            ]);

            d3.selectAll(".data").remove()

            //квадрат на карте
            svg.append("g")
            .attr("class", "data")
            .selectAll("path")
            .data(json.features)
            .join("path")
            // .attr("fill", "#69a2a2")
            .style("fill", function(d) {
                //Get data value
                var value = d.properties.value;
                if (value) {
                    //If value exists…
                    return color(value);
                } else {
                    //If value is undefined…
                    return "#ccc";
                }
            })
            // .attr("x", 400)
            .attr("d", path)
            .call(addTooltip)
            .attr("stroke", "#191919")
            .attr("cursor", "pointer")
    

            svg.append("g")
            .selectAll("labels")
            .data(json.features)
            .join("text")
              .attr("x", function(d){return path.centroid(d)[0]})
              .attr("y", function(d){return path.centroid(d)[1]})
              .attr("cursor", "pointer")
              .text(function(d){ return d.properties.name_short_2})
              .attr("text-anchor", "middle")
              .attr("alignment-baseline", "central")
              .style("font-size", 11)
              .style("fill", "white") 
              .call(addTooltip)

            // Usually you have a color scale in your chart already
            // https://stackoverflow.com/questions/4020796/finding-the-max-value-of-a-property-in-an-array-of-objects
            let x = json.features.map(d => d.properties.value)
            let y = json.features.map(d => d.properties)
            let minValue = Math.min(...x)
            let minName = y.find(region => region.value === minValue).name
            let maxValue = Math.max(...x)
            let maxName = y.find(region => region.value === maxValue).name

            function findMedian(arr) {
                arr.sort((a, b) => a - b);
                const middleIndex = Math.floor(arr.length / 2); 
                if (arr.length % 2 === 0) {
                    return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
                } else {
                    return arr[middleIndex];
                }
            }
            
            // Ищем ближайшее к медианному значение. findMedian(x) -  медиана массива
            let closestToMedian = x.reduce(function(prev, curr) {
                return (Math.abs(curr - findMedian(x)) < Math.abs(prev - findMedian(x)) ? curr : prev);
              });
            
            let medianValue = closestToMedian    
            let medianName = y.find(region => region.value === medianValue).name
            //ищем среднюю
            let averageValue = x.reduce((a, b) => a + b) / x.length
            let closestToAverage = x.reduce(function(prev, curr) {
                return (Math.abs(curr - averageValue) < Math.abs(prev - averageValue) ? curr : prev);
              });
            let averageName = y.find(region => region.value === closestToAverage).name

            let values = [maxValue, averageValue, medianValue, minValue]
            
            const names = () => {
               return [`Макс - ${maxName}: ${maxValue.toFixed(1)} ${METRICS[index].name}`,
               `Ближ. к среднему - ${averageName}: ${averageValue.toFixed(1)} ${METRICS[index].name}`,
               `Ближ. к медиане - ${medianName}: ${medianValue.toFixed(1)} ${METRICS[index].name}`, 
               `Мин - ${minName}: ${minValue.toFixed(1)} ${METRICS[index].name}`]
            }

            const colorvalues = values.map((x) => color(x))

            // Add one dot in the legend for each name.
            const updateColor = (data) =>{
            var size = 20
            svg.selectAll("mydots")
            .data(values)
            .enter()
            .append("rect")
            .attr("x", legendPositionX())
            .attr("y", function(d,i){ return legendPositionY() + i*(size+5)}) // 100 is where the first dot appears. 25 is the distance between dots
            .attr("width", size)
            .attr("height", size)
            .style("fill", function(d){ 
                //var value = d.properties.value
                //return color(value)
                return color(d)
            })
            .merge(svg)
            }
            updateColor(values)
            

            const updateLegend = (data) =>{
            var size = 20
            svg.selectAll(".map__legend").remove()
            // bind data
            const legend = svg.selectAll("mylabels")
            .data(data)
            // add new elements
            legend
            .enter()
            .append("text")
            .attr("class", "map__legend")
            //update existing elements
            .transition()
            .duration(0)
            .attr("x", legendPositionX()+size*1.2)
            .attr("y", function(d,i){ return legendPositionY() +5 + i*(size+5) + (size/2)}) // 100 is where the first dot appears. 25 is the distance between dots
            // .style("fill", "white")
            // .style("font-size", 4)
            .text(function(d){ 
                // var value = d.properties.value;
                return d})  
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")

                legend.exit().remove();
            }

            updateLegend(names())
            })
        })
            return (
            <>
            <Helmet>
                <title>Карта бюджетов России</title>
                <meta name="description" content="Сколько тратят и зарабатывают регионы Россиии? Cколько тратит и зарабатывает федеральный бюджет? Сравнение регионов на карте России. Визуализация бюджетов." />
                <meta property="og:title" content="Карта бюджетов России"/>
                <meta property="og:description" content="Сравнение региональных и федерального бюджетов России"/>
                <meta property="og:image" content={image} />
                <meta property="og:url" content={`https://budgetdata.ru/`}/>
                <meta property="og:type" content="website"/>
                <link rel="canonical" href="/"></link>
            </Helmet>
            <main className="main">
                <div className='map'>
                <h1 className='map__title'>{ARTICLES[item].name_ru}, {METRICS[index].name}, {year}</h1>
                <div className='map__content'>
                <div className='map__panel'>
                <div className='map__buttons'>
                <div className='map__dropdown'>
                <button  id={"1"} className ="map__dropdown-article-button" ref={buttonRef} onClick={(e) => handleOpenButton(e)}  onMouseMove={(e) => handleOpenButton(e)}>Статья&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                {toggleButton && openButton === "1" ? (
                    <ul className="map__dropdown-list map__dropdown-list_metrics" ref={buttonRef} onClick={() => handleCloseButton()} onMouseLeave={() => handleCloseButton()}>
                    <li className="map__dropdown-item ">
                        <button className="map__dropdown-button map__dropdown-button_main" onClick={() => {setItem(ARTICLES[0].id)}}><pre className='map__dropdown-text'>{ARTICLES[0].name_ru}</pre></button>
                    </li>
                    {ARTICLES.filter(item => 3<=item.id && item.id <=15).map((item) =>(
                    <li key={item.id} className="map__dropdown-item">
                    <button className='map__dropdown-button' onClick={() => {setItem(item.id)}}><pre className='map__dropdown-text'>{item.name_ru}</pre></button>
                    </li>
                    ))}
                    <li className="map__dropdown-item">
                        <button className='map__dropdown-button map__dropdown-button_main' onClick={() => {setItem(ARTICLES[1].id)}}><pre className='map__dropdown-text'>{ARTICLES[1].name_ru}</pre></button>
                    </li>
                    {ARTICLES.filter(item => 16<=item.id && item.id <=17).map((item) =>(
                    <li key={item.id} className="map__dropdown-item">
                    <button className='map__dropdown-button' onClick={() => {setItem(item.id)}}><pre className='map__dropdown-text'>{item.name_ru}</pre></button>
                    </li>
                    ))}
                    <li className="map__dropdown-item">
                        <button className='map__dropdown-button map__dropdown-button_main' onClick={() => {setItem(ARTICLES[2].id)}}><pre className='map__dropdown-text'>{ARTICLES[2].name_ru}</pre></button>
                    </li>
                    </ul>) : null}
                </div>
                <div className='map__dropdown map__dropdown_center'>
                <button  id={"2"} className ="map__dropdown-article-button" ref={buttonRef} onClick={(e) => handleOpenButton(e)} onMouseMove={(e) => handleOpenButton(e)}>Единицы измерения&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                {toggleButton && openButton === "2" ? (
                <ul className="map__dropdown-list map__dropdown-list_items" ref={buttonRef} onClick={() => handleCloseButton()} onMouseLeave={() => handleCloseButton()}>
                {METRICS.filter(item => 0<=item.id && item.id <=7).map((item) =>(
                <li key={item.id} className="map__dropdown-item">
                <button className='map__dropdown-button map__dropdown-text' onClick={() => {setIndex(item.id)}}><pre className='map__dropdown-text'>{item.name}</pre></button>
                </li>   
                ))}
                </ul>) : null}
                </div>
                <div className='map__dropdown'>
                <button  id={"3"} className ="map__dropdown-article-button" ref={buttonRef} onClick={(e) => handleOpenButton(e)} onMouseMove={(e) => handleOpenButton(e)}>Год&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                {toggleButton && openButton === "3" ? (
                <ul className="map__dropdown-list map__dropdown-list_years" ref={buttonRef}  onClick={() => handleCloseButton()} onMouseLeave={(e) => handleCloseButton(e)}>
                <li className="map__dropdown-item">
                <button id={2021} className='map__dropdown-button map__dropdown-text' onClick={(e) => {handleYearChange(e)}}>2021</button>
                </li>
                <li className="map__dropdown-item">
                <button id={2020} className='map__dropdown-button map__dropdown-text' onClick={(e) => {handleYearChange(e)}}>2020</button>
                </li>
                </ul>
                ): null}
                </div>  
                </div>
                <div className='map__federal'>
                <p className='map__square-text'>{ARTICLES[item].name_ru}</p>
                <p className='map__square-text'>Федеральный бюджет</p>
                <Link className='map__center-link' to={`./center/${year}`}>
                <div className='map__square'>
                <p className='map__square-value'>{f(p(federalValues))}</p>
                <p className='map__square-value'>{METRICS[index].name}</p>
                </div> 
                </Link>
                </div>
                <p className="map__explanation">
                    {`[Клик x2 - перейти к региону,\n Клик x1 - посмотреть доп. информацию]`}
                </p>
                </div>
                <div className='map__svg-container' ref={mapRef}>
                <svg className='map__svg' id="map__svg"  ref={svgRef}></svg>
                </div>
                <div className='map__arrow map__arrow_europe'>
                    <p className='map__arrow-paragraph'>Е</p>
                    <p className='map__arrow-paragraph'>в</p>
                    <p className='map__arrow-paragraph'>р</p>
                    <p className='map__arrow-paragraph'>о</p>
                    <p className='map__arrow-paragraph'>п</p>
                    <p className='map__arrow-paragraph'>а</p>
                    </div>
                <div className='map__arrow map__arrow_asia'>
                    <p className='map__arrow-paragraph'>А</p>
                    <p className='map__arrow-paragraph'>з</p>
                    <p className='map__arrow-paragraph'>и</p>
                    <p className='map__arrow-paragraph'>я</p>
                </div>
                </div>
                </div>
            </main>
            </>
            )
}

export default Main;
 
