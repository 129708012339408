import {React, useReducer, useEffect} from "react";
import { Routes, Route} from "react-router-dom";
import './App.css';
import Header from '../Header/Header';
import Main from '../Main/Main';
import Regions from '../Regions/Regions';
import Center from '../Center/Center';
import Extra from '../Extra/Extra';
import About from '../About/About';
import Footer from '../Footer/Footer';
import reducer from "../../utils/constants/reducer";
import PageNotFound from "../PageNotFound/PageNotFound";
import { usePageTracking } from "../../utils/tracking";
import usePersistedReducer from '../../utils/constants/reducer';
// import { Context } from '../../contexts/CurrentUserContext'


function App() {

usePageTracking()

const [state, dispatch] = usePersistedReducer(reducer,{
  counter: 1,
} )

  return (
    <>
    <Header state={state} />
    <Routes>
    <Route exact path="/" element={<Main dispatch={dispatch}/>} />
    <Route exact path="/regions/:name/:year"  element={
    // <Context.Provider 
    // value={{place, setPlace}} >
    <Regions state={state} dispatch={dispatch}/>
    // </Context.Provider>
    } />
    <Route exact path="/center/:year" element={<Center />} />
    <Route path="*" element={<PageNotFound />} />
    <Route exact path="/extra/:year" element={<Extra />} />
    <Route exact path="/methodology" element={<About />} />
    </Routes>
    <Footer />
    </>
  );
}

export default App;
